import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { uploadMedia } from '@campgladiator/cg-common.api.media-service'
import { createWorkout } from '@campgladiator/cg-common.api.workouts'
import { type TagDTO } from '@campgladiator/cg-common.types.types'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { useAppAccess } from 'app/contexts/app-access'
import { useGrowlContext } from 'app/contexts/growl-context'
import { type ApiAutocompleteProps } from 'components/form-ui/api-auto-complete/api-auto-complete'
import { deriveContentType } from 'components/form-ui/attachment-field/logic'
import { DropdownItem } from 'components/form-ui/dropdown/dropdown'
import { MediaType } from 'components/form-ui/file-uploader'
import { useWithFileUploader } from 'components/form-ui/file-uploader/use-with-file-uploader'
import { getTags } from 'services/api/tag'
import { getTrainers } from 'services/api/trainer'

const generateFileName = (file: File) => {
  const extension = file.name.split('.').pop() || ''
  const isPhoto = deriveContentType(file) === 'PHOTO'
  const prefix = isPhoto ? 'thumbnail' : 'workout'
  return `${prefix}_${new Date()
    .toISOString()
    .replace(/[:.]/g, '-')}.${extension}`
}

const generateNewFileName = (file: File) => {
  const newFileName = generateFileName(file)
  return new File([file], newFileName, { type: file.type })
}

const getDropdownItems = (tags: TagDTO[], tagType: string) => {
  return tags
    .filter((tag) => tag.tagType === tagType)
    .map((tag: TagDTO) => {
      let label = tag.title
      if (label === 'Cool-Down') {
        label = 'Cool Down'
      }
      return {
        label,
        value: tag.id.toString(),
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
}

const useOnDemandVideoForm = () => {
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const [selectedTrainerPhoto, setSelectedTrainerPhoto] = useState<string>('')
  const [fetchedTags, setFetchedTags] = useState<TagDTO[]>([])
  const [styleOfWorkoutTags, setStyleOfWorkoutTags] = useState<DropdownItem[]>(
    [],
  )
  const [selectedStyleOfWorkoutTag, setSelectedStyleOfWorkoutTag] =
    useState<string>('')
  const [durationTags, setDurationTags] = useState<DropdownItem[]>([])
  const [selectedDurationTag, setSelectedDurationTag] = useState<string>('')
  const [weightFocusTags, setWeightFocusTags] = useState<DropdownItem[]>([])
  const [selectedWeightFocusTag, setSelectedWeightFocusTag] =
    useState<string>('')
  const [cardioFocusTags, setCardioFocusTags] = useState<DropdownItem[]>([])
  const [selectedCardioFocusTag, setSelectedCardioFocusTag] =
    useState<string>('')
  const [bodyFocusTags, setBodyFocusTags] = useState<DropdownItem[]>([])
  const [selectedBodyFocusTag, setSelectedBodyFocusTag] = useState<string>('')
  const [equipmentTags, setEquipmentTags] = useState<DropdownItem[]>([])
  const [selectedEquipmentTag, setSelectedEquipmentTag] = useState<string>('')
  const [defaultEquipmentTags, setDefaultEquipmentTags] = useState<TagDTO[]>([])
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [videoFile, setVideoFile] = useState<File | null>(null)
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const hiddenPhotoInputRef = useRef<HTMLInputElement>(null)
  const hiddenVideoInputRef = useRef<HTMLInputElement>(null)

  const { authToken } = useAppAccess()
  const { showGrowl } = useGrowlContext()
  const navigate = useNavigate()

  const isFormValid = useMemo(() => {
    return (
      selectedTrainer &&
      selectedStyleOfWorkoutTag &&
      selectedDurationTag &&
      title &&
      description &&
      videoFile &&
      thumbnailFile
    )
  }, [
    selectedTrainer,
    selectedStyleOfWorkoutTag,
    selectedDurationTag,
    title,
    description,
    videoFile,
    thumbnailFile,
  ])

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setIsSubmitting(true)

      try {
        if (isFormValid) {
          const selectedTags = [
            selectedStyleOfWorkoutTag,
            selectedDurationTag,
            selectedWeightFocusTag,
            selectedCardioFocusTag,
            selectedBodyFocusTag,
            selectedEquipmentTag,
          ]

          const tags = [
            ...defaultEquipmentTags,
            ...fetchedTags.filter((tag) =>
              selectedTags.includes(tag.id.toString()),
            ),
          ]

          const { name: thumbnailName } = thumbnailFile as File
          const { name: videoName } = videoFile as File

          const [thumbnailUploadResult, videoUploadResult] = await Promise.all([
            uploadMedia({
              bucketName: 'ondemand',
              file: thumbnailFile as File,
              fileName: thumbnailName,
              fileType: 'IMAGE',
            }),
            uploadMedia({
              bucketName: 'ondemand',
              file: videoFile as File,
              fileName: videoName,
              fileType: 'VIDEO',
            }),
          ])

          const { url: thumbnailUrl } = thumbnailUploadResult
          const { url: videoUrl } = videoUploadResult

          const formData = {
            link: videoUrl,
            photo: thumbnailUrl,
            trainerId: selectedTrainer,
            trainerPhoto: selectedTrainerPhoto,
            title,
            description,
            featured: true,
            duration: Number(selectedDurationTag),
            type: 'ONDEMAND',
            tags,
            token: authToken?.token || '',
          }

          await createWorkout(formData)
          showGrowl('SUCCESS', 'Workout created successfully', false)
          setTimeout(() => {
            setIsSubmitting(false)
            navigate(-1)
          }, 2000)
        }
      } catch (error) {
        showGrowl('FAILED', 'Failed to create workout')
        setIsSubmitting(false)
      }
    },
    [
      authToken?.token,
      defaultEquipmentTags,
      description,
      fetchedTags,
      isFormValid,
      selectedBodyFocusTag,
      selectedCardioFocusTag,
      selectedDurationTag,
      selectedEquipmentTag,
      selectedStyleOfWorkoutTag,
      selectedTrainer,
      selectedTrainerPhoto,
      selectedWeightFocusTag,
      thumbnailFile,
      title,
      videoFile,
      navigate,
      showGrowl,
    ],
  )

  const trainerSearchAutoCompleteProps: ApiAutocompleteProps = {
    id: 'trainer',
    name: 'trainer',
    placeholder: 'Trainer',
    getMethod: getTrainers,
    searchKey: 'name',
    renderOptionLabel: (record) => `${record.firstName} ${record.lastName}`,
    onSelect: (record: any) => {
      if (record.item) {
        setSelectedTrainerPhoto(record.item.photo || '')
        setSelectedTrainer(record.itemId || '')
      } else {
        setSelectedTrainerPhoto('')
        setSelectedTrainer('')
      }
    },
    value: selectedTrainer,
  }

  const handleFileUploaderClick = (
    mediaType: MediaType,
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.preventDefault()

    if (mediaType === 'PHOTO') {
      hiddenPhotoInputRef.current?.click()
    } else if (mediaType === 'VIDEO') {
      hiddenVideoInputRef.current?.click()
    }
  }

  const handleFileUploaderInputChange = (
    mediaType: MediaType,
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event?.target.files
    if (files && files.length) {
      const file = files[0]
      const newFile = generateNewFileName(file)
      if (mediaType === 'PHOTO') {
        setThumbnailFile(newFile)
      } else if (mediaType === 'VIDEO') {
        setVideoFile(newFile)
      }
    }
  }

  const handleRemoveFile = (attachment: File) => {
    if (deriveContentType(attachment) === 'PHOTO') {
      setThumbnailFile(null)
    } else if (deriveContentType(attachment) === 'VIDEO') {
      setVideoFile(null)
    }
  }

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) =>
      setter(event.target.value)

  const handleStyleOfWorkoutTagChange = handleInputChange(
    setSelectedStyleOfWorkoutTag,
  )
  const handleDurationTagChange = handleInputChange(setSelectedDurationTag)
  const handleWeightFocusTagChange = handleInputChange(
    setSelectedWeightFocusTag,
  )
  const handleCardioFocusTagChange = handleInputChange(
    setSelectedCardioFocusTag,
  )
  const handleBodyFocusTagChange = handleInputChange(setSelectedBodyFocusTag)
  const handleEquipmentTagChange = handleInputChange(setSelectedEquipmentTag)

  const handleTitleChange = (value: string) => setTitle(value)

  const handleDescriptionChange = (value: string) => setDescription(value)

  const fileUploaderVideo = useWithFileUploader({
    disabled: isSubmitting,
    fileInputRef: hiddenVideoInputRef,
    onChange: handleFileUploaderInputChange,
    onClick: handleFileUploaderClick,
    text: 'Upload video',
    mediaType: 'VIDEO',
  })

  const fileUploaderThumb = useWithFileUploader({
    disabled: isSubmitting,
    fileInputRef: hiddenPhotoInputRef,
    onChange: handleFileUploaderInputChange,
    onClick: handleFileUploaderClick,
    text: 'Upload video thumbnail',
    mediaType: 'PHOTO',
  })

  const trainerFormProps = {
    formLabel: 'Trainer*',
    formForId: 'trainerId',
    trainerSearchAutoCompleteProps,
  }

  const styleOfWorkoutFormProps = {
    formLabel: 'Style of Workout*',
    formForId: 'styleOfWorkoutId',
    dropdownId: 'styleOfWorkout',
    dropdownLabel: 'Choose a style',
    styleOfWorkoutTags,
    selectedStyleOfWorkoutTag,
    handleStyleOfWorkoutTagChange,
  }

  const durationTagFormProps = {
    formLabel: 'Duration*',
    formForId: 'durationId',
    dropdownId: 'duration',
    dropdownLabel: 'Choose a duration',
    durationTags,
    selectedDurationTag,
    handleDurationTagChange,
  }

  const weightFocusTagFormProps = {
    formLabel: 'Weight Focus',
    formForId: 'weightFocusId',
    dropdownId: 'weightFocus',
    dropdownLabel: 'Select Option',
    weightFocusTags,
    handleWeightFocusTagChange,
  }

  const cardioFocusTagFormProps = {
    formLabel: 'Cardio Focus',
    formForId: 'cardioFocusId',
    dropdownId: 'cardioFocus',
    dropdownLabel: 'Select Option',
    cardioFocusTags,
    handleCardioFocusTagChange,
  }

  const bodyFocusTagFormProps = {
    formLabel: 'Body Focus',
    formForId: 'bodyFocusId',
    dropdownId: 'bodyFocus',
    dropdownLabel: 'Select Option',
    bodyFocusTags,
    handleBodyFocusTagChange,
  }

  const equipmentTagFormProps = {
    formLabel: 'Equipment',
    formForId: 'weightsBodyweightId',
    dropdownId: 'weightsBodyweight',
    dropdownLabel: 'Select Option',
    equipmentTags,
    selectedEquipmentTag,
    handleEquipmentTagChange,
  }

  const titleFormProps = {
    formLabel: 'Title*',
    formForId: 'titleId',
    rows: 4,
    textAreaId: 'title',
    textAreaName: 'title',
    textAreaPlaceholder: 'Title',
    handleTitleChange,
  }

  const descriptionFormProps = {
    formLabel: 'Description*',
    formForId: 'descriptionId',
    rows: 4,
    textAreaId: 'description',
    textAreaName: 'description',
    textAreaPlaceholder: 'Description',
    handleDescriptionChange,
  }

  const submitButtonText = isSubmitting
    ? 'Publishing...'
    : 'publish new ondemand video'

  const saveNewOnDemandVideoButtonProps: ButtonProps = {
    children: submitButtonText,
    emphasis: 'primary',
    size: 'large',
    theme: 'trainer',
    type: 'submit',
    disabled: !isFormValid || isSubmitting,
  }

  const fetchTags = useCallback(async () => {
    const tags = await getTags(true)
    setFetchedTags(tags)

    const preselectedTags = ['mat', 'water', 'towel']
    const preselectedTagIds = tags.filter((tag) =>
      preselectedTags.includes(tag.title.toLowerCase()),
    )
    setDefaultEquipmentTags(preselectedTagIds)

    const dropdownStyleItems = getDropdownItems(tags, 'style')
    const dropdownDurationItems = getDropdownItems(tags, 'duration')
    const weightFocusItems = getDropdownItems(tags, 'weight focus')
    const cardioFocusItems = getDropdownItems(tags, 'cardio focus')
    const bodyFocusItems = getDropdownItems(tags, 'body focus')

    const equipmentItems = tags
      .filter((tag) => tag.tagType === 'equipment')
      .map((tag: TagDTO) => ({
        label: tag.title,
        value: tag.id.toString(),
        disabled: preselectedTags.includes(tag.title.toLowerCase()),
      }))

    const activeEquipmentItems = equipmentItems
      .filter((item) => !item.disabled)
      .sort((a, b) => a.label.localeCompare(b.label))

    const inactiveEquipmentItems = equipmentItems
      .filter((item) => item.disabled)
      .sort((a, b) => a.label.localeCompare(b.label))

    const sortedEquipmentItems = [
      ...activeEquipmentItems,
      ...inactiveEquipmentItems,
    ]

    setStyleOfWorkoutTags(dropdownStyleItems)
    setDurationTags(dropdownDurationItems)
    setWeightFocusTags(weightFocusItems)
    setCardioFocusTags(cardioFocusItems)
    setBodyFocusTags(bodyFocusItems)
    setEquipmentTags(sortedEquipmentItems)

    if (dropdownStyleItems.length) {
      const defaultStyleTag = dropdownStyleItems.find(
        (tag) => tag.label === 'Camp',
      )
      const defaultDurationTag = dropdownDurationItems.find(
        (tag) => tag.label.toLowerCase() === '60 minutes',
      )
      if (defaultStyleTag) {
        setSelectedStyleOfWorkoutTag(defaultStyleTag.value)
      }
      if (defaultDurationTag) {
        setSelectedDurationTag(defaultDurationTag.value)
      }
    }
  }, [])

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  return {
    bodyFocusTagFormProps,
    cardioFocusTagFormProps,
    descriptionFormProps,
    durationTagFormProps,
    equipmentTagFormProps,
    fileUploaderThumb,
    fileUploaderVideo,
    saveNewOnDemandVideoButtonProps,
    styleOfWorkoutFormProps,
    videoFile,
    thumbnailFile,
    titleFormProps,
    trainerFormProps,
    weightFocusTagFormProps,
    handleRemoveFile,
    handleSubmit,
  }
}

export default useOnDemandVideoForm
